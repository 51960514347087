
// import React from 'react';
// import { SafeAreaView, StyleSheet, ScrollView } from 'react-native';
// import CalendarComponent from '../components/CalendarComponent';
// import { Link } from '@react-navigation/native';
// const HomeScreen = ({ navigation }) => {
//   return (
//     <SafeAreaView style={styles.container}>
//         <CalendarComponent navigation={navigation} />
      
//     </SafeAreaView>
//   );
// };

// const styles = StyleSheet.create({
//   container: {
//     flex: 1,
  
//   },
// });

// export default HomeScreen;
import React from 'react';
import { SafeAreaView, StyleSheet, ScrollView } from 'react-native';
import CalendarComponent from '../components/CalendarComponent';
import { Link } from '@react-navigation/native';

const HomeScreen = ({ navigation }) => {
  return (
    <SafeAreaView style={styles.container}>
     
        {/* Si necesitas pasar la navegación al componente CalendarComponent */}
        <CalendarComponent navigation={navigation} />
        {/* Si quieres añadir un Link en HomeScreen para navegar */}
        {/* <Link to="/admin" style={styles.link}>
        </Link> */}
     
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  link: {
    padding: 1, // Estilo de ejemplo, ajústalo a tus necesidades
  },
  // Añade más estilos aquí según necesites
});

export default HomeScreen;
