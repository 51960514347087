import React, { useState } from 'react';
import { View, TextInput, Button, StyleSheet, Text, Alert, ImageBackground, Keyboard } from 'react-native';
import { login } from '../api/EventApi';
import { useAuth } from '../context/AuthContext';

const LoginScreen = ({ navigation }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const { login: contextLogin } = useAuth();

  const handleLogin = async () => {
    if (!username || !password) {
      Alert.alert('Error', 'Por favor, completa todos los campos');
      return;
    }

    try {
      const data = await login(username, password);
      if (data.auth && data.token) {
        contextLogin({ username, token: data.token });
        Alert.alert('Login Success', 'You are logged in!');
        navigation.navigate('Admin');
      } else {
        throw new Error('Authentication failed');
      }
    } catch (error) {
      Alert.alert('Login Error', error.message);
    }
  };

  const handleKeyPress = ({ nativeEvent }) => {
    if (nativeEvent.key === 'Enter') {
      Keyboard.dismiss(); // Cierra el teclado
      handleLogin();
    }
  };

  return (
    <ImageBackground
      source={require('../images/pndescarga.jpg')}
      style={styles.background}
    >
      <View style={styles.overlay}>
        <View style={styles.container}>
          <Text style={styles.label}>Usuario:</Text>
          <TextInput
            style={styles.input}
            value={username}
            onChangeText={setUsername}
            placeholder="Ingresar usuario"
            placeholderTextColor="#999"
            onKeyPress={handleKeyPress}
            returnKeyType="next"
          />
          <Text style={styles.label}>Contraseña:</Text>
          <TextInput
            style={styles.input}
            value={password}
            onChangeText={setPassword}
            secureTextEntry
            placeholder="Ingresar contraseña"
            placeholderTextColor="#999"
            onKeyPress={handleKeyPress}
            returnKeyType="done"
          />
          <Button title="Ingresar" onPress={handleLogin} />
        </View>
      </View>
    </ImageBackground>
  );
};

const styles = StyleSheet.create({
  background: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  overlay: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.3)',
    width: '100%',
  },
  container: {
    backgroundColor: 'rgba(255, 255, 255, 0.85)',
    padding: 15, // Reduce el padding para hacer el contenedor más pequeño
    borderRadius: 12,
    width: '80%', // Ajusta el ancho del contenedor
    maxWidth: 350, // Reduce el ancho máximo del contenedor
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    alignItems: 'center', // Centra el contenido del formulario
  },
  label: {
    fontSize: 16,
    color: '#444', // Letras más oscuras
    marginBottom: 8,
  },
  input: {
    height: 35,
    width: '100%', // Asegura que el input use todo el ancho disponible
    marginBottom: 12,
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    paddingHorizontal: 10,
    color: '#333',
  },
});

export default LoginScreen;
