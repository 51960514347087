import React, { useState, useEffect } from 'react';
import { View, Modal, StyleSheet, Dimensions, Text, TouchableOpacity, TextInput, Picker, Button, ScrollView, Alert } from 'react-native';
import { Calendar } from 'react-native-big-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import HatchPattern from '../components/HatchPattern'; 
import customParseFormat from 'dayjs/plugin/customParseFormat';
// import Icon from 'react-native-vector-icons/MaterialIcons';
// import Feather from 'react-native-vector-icons/Feather';
import { MdAddCircle, MdDelete, MdClose } from "react-icons/md";
import { FiEdit2 } from "react-icons/fi";


// import { MaterialIcons as Icon } from '@expo/vector-icons';
// import { Feather } from '@expo/vector-icons';
import DateTimePicker from 'react-native-ui-datepicker';
import { Calendar as CalendarPicker , LocaleConfig } from 'react-native-calendars'; // Importa el componente Calendar de react-native-calendars para el picker
import { createEvent, getEvents,  deleteEvent as apiDeleteEvent , updateEventApi  } from '../api/EventApi'; // Asegúrate de que la ruta es correcta
import { TimePickerModal } from 'react-native-paper-dates';
import Svg, { Path } from 'react-native-svg';
dayjs.extend(customParseFormat);
dayjs.locale('es');

dayjs.extend(utc);
dayjs.extend(timezone);

// Define your custom arrow SVG component
const Arrow = ({ direction }) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <Path
      d={direction === 'left' ? 'M15 19l-7-7 7-7' : 'M9 5l7 7-7 7'}
      stroke="#4484f4"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </Svg>
);

const getDynamicOverlapOffset = (windowWidth) => {
  const baseWidth = 1024;
  const baseOffset = 62;
  const minimumOffset = 45;
  const calculatedOffset = (windowWidth / baseWidth) * baseOffset;
  return Math.max(calculatedOffset, minimumOffset);
};
// Función para calcular el tamaño de fuente dinámico
const getDynamicFontSize = () => {
  const baseFontSize = 11; // Tamaño de fuente base
  const smallScreenFontSize = 6; // Tamaño de fuente para pantallas pequeñas
  const largeScreenFontSize = 11; // Tamaño de fuente para pantallas grandes
  const maxScreenWidth = 1440; // Ancho máximo de pantalla para considerar

  // Calcula el tamaño de la fuente basado en la proporción de la pantalla
  if (screenWidth <= 768) {
    // Pantallas pequeñas (como móviles)
    return smallScreenFontSize;
  } else if (screenWidth >= maxScreenWidth) {
    // Pantallas grandes (como escritorio)
    return largeScreenFontSize;
  } else {
    // Interpolación lineal para pantallas medianas
    return (
      smallScreenFontSize +
      ((screenWidth - 768) / (maxScreenWidth - 768)) * (largeScreenFontSize - smallScreenFontSize)
    );
  }
};

const screenHeight = Dimensions.get('window').height;
const { width: screenWidth } = Dimensions.get('window');
const checkDate = (dateString) => {
  return dayjs(dateString, 'YYYY-MM-DDTHH:mm:ss').toDate();
};

const initialEvents = [
  {
    title: "LM Don Tito I",
    description: {
      nave: "Don Tito I",
      tonAnunciado: "80Ton",
      especie: "Jurel",
      ponton: "1"
    },
    start: checkDate("2024-04-08T14:00:00"),
    end: checkDate("2024-04-08T15:00:00"),
    color: 'blue',
  },
  // Otros eventos
];

const AdminCalendarScreen = () => {
  const [events, setEvents] = useState([]);
  
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const [addEventModalVisible, setAddEventModalVisible] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState('');
  const [newEventDescription, setNewEventDescription] = useState('');

  const [newEventDuration, setNewEventDuration] = useState('30');
  const [date, setDate] = useState(dayjs());


// Mantén la fecha y la hora como estados separados
const [newEventDate, setNewEventDate] = useState(dayjs());
const [newEventHour, setNewEventHour] = useState(dayjs().format('HH'));
const [newEventMinute, setNewEventMinute] = useState(dayjs().format('mm'));

//inputs picker
const [selectedNave, setSelectedNave] = useState('');
const [selectedPonton, setSelectedPonton] = useState('');
const [selectedEspecie, setSelectedEspecie] = useState('');
const [tonAnunciado, setTonAnunciado] = useState('');
const [selected, setSelected] = useState('');


// timepicker libreria
const [startTime, setStartTime] = useState({ hours: 12, minutes: 0 });
  const [endTime, setEndTime] = useState({ hours: 13, minutes: 0 });
  const [isStartPickerVisible, setStartPickerVisible] = useState(false);
  const [isEndPickerVisible, setEndPickerVisible] = useState(false);
  const [newEventEndHour, setNewEventEndHour] = useState(dayjs().format('HH')); // Establece la hora actual como hora inicial
  const [newEventEndMinute, setNewEventEndMinute] = useState(dayjs().format('mm')); // Establece los minutos actuales como minutos iniciales
  const [isEndDateVisible, setEndDateVisible] = useState(false);
 const [isStartDateVisible, setStartDateVisible] = useState(false);

const [newEventEndDate, setNewEventEndDate] = useState(dayjs());


const [eventType, setEventType] = useState('Normal');



//variables editar
const [editEventModalVisible, setEditEventModalVisible] = useState(false);
const [editEventId, setEditEventId] = useState(null);
const [editEventTitle, setEditEventTitle] = useState('');
const [editEventDescription, setEditEventDescription] = useState('');
const [editEventDate, setEditEventDate] = useState(dayjs());
const [editStartTime, setEditStartTime] = useState({ hours: 12, minutes: 0 });
const [editEndTime, setEditEndTime] = useState({ hours: 13, minutes: 0 });
const [editSelectedNave, setEditSelectedNave] = useState('');
const [editSelectedPonton, setEditSelectedPonton] = useState('');
const [editSelectedEspecie, setEditSelectedEspecie] = useState('');
const [editTonAnunciado, setEditTonAnunciado] = useState('');
const [editEventType, setEditEventType] = useState('Normal');
const [isEditEndDateVisible, setEditEndDateVisible] = useState(false);
const [editEventEndDate, setEditEventEndDate] = useState(dayjs());


const [currentDate, setCurrentDate] = useState(new Date());
//botones avanzar semanas
const goToPreviousWeek = () => {
  setCurrentDate(dayjs(currentDate).subtract(1, 'week').toDate());
};

const goToNextWeek = () => {
  setCurrentDate(dayjs(currentDate).add(1, 'week').toDate());
};

const goToCurrentWeek = () => {
  setCurrentDate(new Date());
};

  const onDismissStartPicker = () => {
    setStartPickerVisible(false);
  };

  // const onConfirmStartPicker = ({ hours, minutes }) => {
  //   setStartPickerVisible(false);
  //   setStartTime({ hours, minutes });
  // };

  const onDismissEndPicker = () => {
    setEndPickerVisible(false);
  };

  // const onConfirmEndPicker = ({ hours, minutes }) => {
  //   setEndPickerVisible(false);
  //   setEndTime({ hours, minutes });
  // };





  const onConfirmStartPicker = ({ hours, minutes }) => {
    setStartPickerVisible(false);
    setStartTime({ hours, minutes });
    setEditStartTime({ hours, minutes }); // Asegura que también actualice el tiempo de edición si está editando
  };
  
  const onConfirmEndPicker = ({ hours, minutes }) => {
    setEndPickerVisible(false);
    setEndTime({ hours, minutes });
    setEditEndTime({ hours, minutes }); // Asegura que también actualice el tiempo de edición si está editando
  };
  

// CODIGO DE RESET MODAL
const resetFields = () => {
  setTonAnunciado('');
  setSelectedNave('');
  setSelectedPonton('');
  setSelectedEspecie('');
  setStartDateVisible(false);
  setEndDateVisible(false);
  setNewEventDate(dayjs());
  setNewEventEndDate(dayjs());
  setStartTime({ hours: 12, minutes: 0 });
  setEndTime({ hours: 13, minutes: 0});
  setEventType('Normal');
};

//

LocaleConfig.locales['es'] = {
  monthNames: [
    'Enero','Febrero','Marzo','Abril','Mayo','Junio',
    'Julio','Agosto','Septiembre','Octubre','Noviembre','Diciembre'
  ],
  monthNamesShort: [
    'Ene.','Feb.','Mar','Abr','May','Jun',
    'Jul.','Ago','Sep.','Oct.','Nov.','Dic.'
  ],
  dayNames: [
    'Domingo','Lunes','Martes','Miércoles','Jueves','Viernes','Sábado'
  ],
  dayNamesShort: ['Dom.','Lun.','Mar.','Mié.','Jue.','Vie.','Sáb.'],
  today: 'Hoy'
};
LocaleConfig.defaultLocale = 'es';



const naves = [
  "PAM ALBIMER",
  "PAM CLAUDIA ALEJANDRA",
  "PAM COLLEN - EX ATACAMA V",
  "PAM ATACAMA IV",
  "PAM LICANTEN",
  "PAM COSTA GRANDE I",
  "ABDON-I",
  "ABEL",
  "ABRAHAM",
  "ANDREAS",
  "ARIES I",
  "CAMILA ANTONIA",
  "CAROLINA I",
  "CATALINA Q",
  "CESAR MIGUEL",
  "DON MAURICIO I",
  "DON SEBASTIAN",
  "FERNANDA I",
  "GIANPIERO I",
  "GRACIAS A DIOS I",
  "GUERRERO DEL GOLFO",
  "ISAAC",
  "ISIDORA I",
  "JORGE HERNAN M",
  "JOSUE",
  "LILIANA",
  "LOBO DE AFUERA V",
  "LOBO DE AFUERA VII",
  "MAR TERCERO",
  "NIÑA XIMENA",
  "RENATA",
  "RUTH-M",
  "VALENTINA",
  "TAM DUQUECO",
  "TAM EMILIA"
];

const pontones = ["Cavancha", "Playa Brava"];
const especies = ["Jurel", "Anchoveta", "Caballa" , "J-C"];
//trigger actualizar get eventos al crear uno nuevo
const [eventUpdateTrigger, setEventUpdateTrigger] = useState(0); // Estado para forzar la actualización de eventos







//EDITAR RELLENA CAMPOS
const openEditModal = (event) => {
  if (!event) return;

  setEditEventId(event.id || null);
  setEditEventTitle(event.title || '');
  setEditEventDescription(event.description || {});
  setEditEventDate(dayjs(event.start) || dayjs());
  setEditEventEndDate(dayjs(event.end) || dayjs()); // Agrega la fecha de fin
  setEditStartTime({ hours: dayjs(event.start).hour(), minutes: dayjs(event.start).minute() });
  setEditEndTime({ hours: dayjs(event.end).hour(), minutes: dayjs(event.end).minute() });
  setEditSelectedNave(event.description.nave || '');
  setEditSelectedPonton(event.description.ponton || '');
  setEditSelectedEspecie(event.description.especie || '');
  setEditTonAnunciado(event.description.tonAnunciado || '');
  setEditEventType(event.title === "Pontón Fuera de Servicio" ? 'Fuera de servicio' : 'Normal');
  setEditEventModalVisible(true);
  setModalVisible(false); // Cierra el modal de detalles
};


//FUNCION ACTUALIZAR EVENTO
const updateEvent = async () => {
  let startDateTime = dayjs(`${editEventDate.format('YYYY-MM-DD')}T${editStartTime.hours.toString().padStart(2, '0')}:${editStartTime.minutes.toString().padStart(2, '0')}`);
  let endDateTime = dayjs(`${editEventDate.format('YYYY-MM-DD')}T${editEndTime.hours.toString().padStart(2, '0')}:${editEndTime.minutes.toString().padStart(2, '0')}`);

  // Verifica si el evento termina a las 00:00
  if (endDateTime.hour() === 0 && endDateTime.minute() === 0) {
    // Ajusta el evento para que termine a las 23:59 del mismo día
    endDateTime = endDateTime.subtract(1, 'minute');
  }

  if (editEventType === 'Normal') {
    if (!editTonAnunciado || !editSelectedNave || !editSelectedPonton || !editSelectedEspecie) {
      alert('Todos los campos son requeridos');
      return;
    }

    if (endDateTime.isBefore(startDateTime)) {
      alert("La hora de fin debe ser después de la hora de inicio.");
      return;
    }

    const overlappingEvents = events.filter(event =>
      event.id !== editEventId && // Excluir el evento actual
      event.description.ponton === editSelectedPonton &&
      (
        (dayjs(event.start).isBefore(endDateTime) && dayjs(event.end).isAfter(startDateTime))
      )
    );

    if (overlappingEvents.length >= 1) {
      alert(`Ya existe un evento en este intervalo para el pontón ${editSelectedPonton}. No se puede actualizar este evento.`);
      return;
    }

    const updatedEvent = {
      id: editEventId,
      title: editSelectedNave,
      description: {
        nave: editSelectedNave,
        ponton: editSelectedPonton,
        especie: editSelectedEspecie,
        tonAnunciado: editTonAnunciado
      },
      start: startDateTime.toDate(),
      end: endDateTime.toDate(),
      color: editSelectedPonton === "Cavancha" ? "#fc641a" : "#039be5", // Naranja para Cavancha, azul para los demás
    };

    try {
      const result = await updateEventApi(updatedEvent); // Llamada a la API
      setEvents(events.map(event => event.id === editEventId ? { ...event, ...updatedEvent } : event)); // Asegúrate de mantener todas las propiedades del evento
      setEditEventModalVisible(false);
      alert("Evento actualizado con éxito.");
    } catch (error) {
      console.error("Error al actualizar el evento:", error);
      alert("Error al actualizar el evento.");
    }
  } else if (editEventType === 'Fuera de servicio') {
    if (!editSelectedPonton) {
      alert('Debe seleccionar un pontón para deshabilitar.');
      return;
    }

    const overlappingEvents = events.filter(event =>
      event.id !== editEventId && // Excluir el evento actual
      event.description.ponton === editSelectedPonton &&
      (
        (dayjs(event.start).isBefore(endDateTime) && dayjs(event.end).isAfter(startDateTime))
      )
    );

    if (overlappingEvents.length >= 1) {
      alert(`Ya existe un evento en este intervalo para el pontón ${editSelectedPonton}. No se puede actualizar este evento.`);
      return;
    }

    const updatedEvent = {
      id: editEventId,
      title: "Pontón Fuera de Servicio",
      description: {
        ponton: editSelectedPonton
      },
      start: startDateTime.toDate(),
      end: endDateTime.toDate(),
      color: 'red', // Color para el patrón achurado
    };

    try {
      const result = await updateEventApi(updatedEvent); // Llamada a la API
      setEvents(events.map(event => event.id === editEventId ? { ...event, ...updatedEvent } : event)); // Asegúrate de mantener todas las propiedades del evento
      setEditEventModalVisible(false);
      alert("Evento de pontón fuera de servicio actualizado con éxito.");
    } catch (error) {
      console.error("Error al actualizar el evento:", error);
      alert("Error al actualizar el evento.");
    }
  }
};






// FUNCION CREACION NUEVO EVENTO
// const addNewEvent = async () => {
//   // Crea una nueva instancia de Day.js con la fecha y hora combinadas.
//   let startDateTime = dayjs(`${newEventDate.format('YYYY-MM-DD')}T${newEventHour.padStart(2, '0')}:${newEventMinute.padStart(2, '0')}`);

//   // Asume una duración de evento por defecto de 30 minutos
//   let endDateTime = startDateTime.add(30, 'minute');

//   // Verifica si el evento termina a las 00:00
//   if (endDateTime.hour() === 0 && endDateTime.minute() === 0) {
//     // Ajusta el evento para que termine a las 23:59 del día anterior
//     endDateTime = startDateTime.set('hour', 23).set('minute', 59);
//   }

//   const eventTitle = naves.find(nave => nave.value === selectedNave)?.label || 'Evento sin título';

//   const newEvent = {
//           title: selectedNave,
//           description: {
//             nave: selectedNave,
//             ponton: selectedPonton,
//             especie: selectedEspecie,
//             tonAnunciado: tonAnunciado
//           },
//           start: startDateTime.toDate(),
//           end: endDateTime.toDate(),
//           color: 'blue', // O el color que desees
//         };

//   try {
//     const createdEvent = await createEvent(newEvent);
//     setEventUpdateTrigger(oldTrigger => oldTrigger + 1); // Incrementa la bandera para forzar la actualización de eventos
//     setAddEventModalVisible(false); // Cierra el modal y limpia los estados/formulario
//   } catch (error) {
//     console.error("Error al crear el evento:", error);
//   }
// }



//FUNCION CREAR EVENTO ANTIGUOOOOO
// const addNewEvent = async () => {
//     const startDateTime = dayjs(`${newEventDate.format('YYYY-MM-DD')}T${startTime.hours.toString().padStart(2, '0')}:${startTime.minutes.toString().padStart(2, '0')}`);
//     const endDateTime = dayjs(`${newEventEndDate.format('YYYY-MM-DD')}T${endTime.hours.toString().padStart(2, '0')}:${endTime.minutes.toString().padStart(2, '0')}`);

//     if (eventType === 'Normal') {
//       if (!tonAnunciado || !selectedNave || !selectedPonton || !selectedEspecie) {
//         alert('Todos los campos son requeridos');
//         return;
//       }

//       if (endDateTime.isBefore(startDateTime)) {
//         alert("La hora de fin debe ser después de la hora de inicio.");
//         return;
//       }

//       const overlappingEvents = events.filter(event =>
//         event.description.ponton === selectedPonton &&
//         (
//           (dayjs(event.start).isSame(startDateTime) || dayjs(event.start).isBefore(endDateTime)) &&
//           (dayjs(event.end).isSame(endDateTime) || dayjs(event.end).isAfter(startDateTime))
//         )
//       );

//       if (overlappingEvents.length >= 1) {
//         alert(`Ya existe un evento en este intervalo para el pontón ${selectedPonton}. No se puede crear otro.`);
//         return;
//       }

//       const newEvent = {
//         title: selectedNave,
//         description: {
//           nave: selectedNave,
//           ponton: selectedPonton,
//           especie: selectedEspecie,
//           tonAnunciado: tonAnunciado
//         },
//         start: startDateTime.toDate(),
//         end: endDateTime.toDate(),
//         color: selectedPonton === "Cavancha" ? "rgba(252, 100, 26, 0.5)" : "rgba(3, 155, 229, 0.5)", // Naranja con opacidad para Cavancha, azul con opacidad para los demás
//       };

//       try {
//         const createdEvent = await createEvent(newEvent);
//         setEventUpdateTrigger(oldTrigger => oldTrigger + 1);
//         resetFields();
//         setAddEventModalVisible(false);
//         alert("Evento creado con éxito.");
//       } catch (error) {
//         console.error("Error al crear el evento:", error);
//         alert("Error al crear el evento.");
//       }
//     } else if (eventType === 'Fuera de servicio') {
//       if (!selectedPonton) {
//         alert('Debe seleccionar un pontón para deshabilitar.');
//         return;
//       }

//       const newEvent = {
//         title: "Pontón Fuera de Servicio",
//         description: {
//           ponton: selectedPonton
//         },
//         start: startDateTime.toDate(),
//         end: endDateTime.toDate(),
//         color: selectedPonton === "Cavancha" ? "rgba(252, 100, 26, 0.5)" : "rgba(3, 155, 229, 0.5)", // Color con opacidad
//       };

//       try {
//         const createdEvent = await createEvent(newEvent);
//         setEventUpdateTrigger(oldTrigger => oldTrigger + 1);
//         resetFields();
//         setAddEventModalVisible(false);
//         alert("Evento de pontón fuera de servicio creado con éxito.");
//       } catch (error) {
//         console.error("Error al crear el evento:", error);
//         alert("Error al crear el evento.");
//       }
//     }
//   };

const addNewEvent = async () => {
  let startDateTime = dayjs(`${newEventDate.format('YYYY-MM-DD')}T${startTime.hours.toString().padStart(2, '0')}:${startTime.minutes.toString().padStart(2, '0')}`);
  let endDateTime = dayjs(`${newEventEndDate.format('YYYY-MM-DD')}T${endTime.hours.toString().padStart(2, '0')}:${endTime.minutes.toString().padStart(2, '0')}`);

  // Verifica si el evento termina a las 00:00
  if (endDateTime.hour() === 0 && endDateTime.minute() === 0) {
    // Ajusta el evento para que termine a las 23:59 del mismo día
    endDateTime = endDateTime.subtract(1, 'minute');
  }

  if (eventType === 'Normal') {
    if (!tonAnunciado || !selectedNave || !selectedPonton || !selectedEspecie) {
      alert('Todos los campos son requeridos');
      return;
    }

    if (endDateTime.isBefore(startDateTime)) {
      alert("La hora de fin debe ser después de la hora de inicio.");
      return;
    }

    const overlappingEvents = events.filter(event =>
      event.description.ponton === selectedPonton &&
      (
        (dayjs(event.start).isSame(startDateTime) || dayjs(event.start).isBefore(endDateTime)) &&
        (dayjs(event.end).isSame(endDateTime) || dayjs(event.end).isAfter(startDateTime))
      )
    );

    if (overlappingEvents.length >= 1) {
      alert(`Ya existe un evento en este intervalo para el pontón ${selectedPonton}. No se puede crear otro.`);
      return;
    }

    const newEvent = {
      title: selectedNave,
      description: {
        nave: selectedNave,
        ponton: selectedPonton,
        especie: selectedEspecie,
        tonAnunciado: tonAnunciado
      },
      start: startDateTime.toDate(),
      end: endDateTime.toDate(),
      color: selectedPonton === "Cavancha" ? "#fc641a" : "#039be5", // Naranja para Cavancha, azul para los demás
    };

    try {
      const createdEvent = await createEvent(newEvent);
      setEventUpdateTrigger(oldTrigger => oldTrigger + 1);
      resetFields();
      setAddEventModalVisible(false);
      alert("Evento creado con éxito.");
    } catch (error) {
      console.error("Error al crear el evento:", error);
      alert("Error al crear el evento.");
    }
  } else if (eventType === 'Fuera de servicio') {
    if (!selectedPonton) {
      alert('Debe seleccionar un pontón para deshabilitar.');
      return;
    }

    const overlappingEvents = events.filter(event =>
      event.description.ponton === selectedPonton &&
      (
        (dayjs(event.start).isSame(startDateTime) || dayjs(event.start).isBefore(endDateTime)) &&
        (dayjs(event.end).isSame(endDateTime) || dayjs(event.end).isAfter(startDateTime))
      )
    );

    if (overlappingEvents.length >= 1) {
      alert(`Ya existe un evento en este intervalo para el pontón ${selectedPonton}. No se puede crear otro.`);
      return;
    }

    const newEvent = {
      title: "Pontón Fuera de Servicio",
      description: {
        ponton: selectedPonton
      },
      start: startDateTime.toDate(),
      end: endDateTime.toDate(),
      color: 'red', // Color para el patrón achurado
    };

    try {
      const createdEvent = await createEvent(newEvent);
      setEventUpdateTrigger(oldTrigger => oldTrigger + 1);
      resetFields();
      setAddEventModalVisible(false);
      alert("Evento de pontón fuera de servicio creado con éxito.");
    } catch (error) {
      console.error("Error al crear el evento:", error);
      alert("Error al crear el evento.");
    }
  }
};



const closeModal = () => {
  resetFields();
  setAddEventModalVisible(false);
};














  const handleTimeChange = (selectedTime) => {
    const [hours, minutes] = selectedTime.split(':');
    setNewEventHour(hours);
    setNewEventMinute(minutes);
  };
  
  

  const [customOverlapOffset, setCustomOverlapOffset] = useState(() => {
    const initialWidth = Dimensions.get('window').width;
    return getDynamicOverlapOffset(initialWidth);
  });

  useEffect(() => {
    const updateOffset = () => {
      const newWidth = Dimensions.get('window').width;
      setCustomOverlapOffset(getDynamicOverlapOffset(newWidth));
    };

    Dimensions.addEventListener('change', updateOffset);

    return () => Dimensions.removeEventListener('change', updateOffset);
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await getEvents();
        const adjustedEvents = fetchedEvents.map(event => {
          // Convertir las fechas a UTC desde la API
          const start = dayjs(event.start).utc().toDate();
          const end = dayjs(event.end).utc().toDate();

          console.log(`Event ID: ${event.id}`);
          console.log(`Hora Inicio Api: ${event.start}`);
          console.log(`Adjusted Start: ${start} (${start.toISOString()})`);
          console.log(`Hora Termino Api: ${event.end}`);
          console.log(`Adjusted End: ${end} (${end.toISOString()})`);
          console.log(`Current Timezone: ${dayjs.tz.guess()}`);
          console.log(`System Timezone: ${Intl.DateTimeFormat().resolvedOptions().timeZone}`);
          console.log(`System Date/Time: ${new Date().toString()}`);

          return {
            id: event.id,
            title: event.title,
            description: event.description,
            start: start,
            end: end,
            color: event.color,
          };
        });

        setEvents(adjustedEvents);
      } catch (error) {
        console.error("Error al obtener eventos:", error);
      }
    };

    fetchEvents();
  }, [eventUpdateTrigger]);







  const handleEventPress = (event) => {
    setSelectedEvent(event);
    
    setModalVisible(true);
  };

  const deleteEvent = async () => {
    if (!selectedEvent || !selectedEvent.id) {
      console.error('selectedEvent o selectedEvent.id no está definido');
      alert('No se puede eliminar el evento seleccionado.');
      return;
    }

    try {
      await apiDeleteEvent(selectedEvent.id); // Llama a la API para eliminar el evento
      setEvents(events.filter((e) => e.id !== selectedEvent.id)); // Actualiza el estado local de los eventos
      setModalVisible(false);
      alert('Evento eliminado exitosamente.');
    } catch (error) {
      console.error('Error al eliminar el evento:', error);
      alert('Error al eliminar el evento.');
    }
  };
  

  const editEvent = () => {
    if (!selectedEvent) return;
  
    setEditEventId(selectedEvent.id);
    setEditEventTitle(selectedEvent.title);
    setEditEventDescription(selectedEvent.description);
    setEditEventDate(dayjs(selectedEvent.start));
    setEditEventEndDate(dayjs(selectedEvent.end)); // Añadir esta línea para establecer la fecha de fin
    
    setEditStartTime({ hours: dayjs(selectedEvent.start).hour(), minutes: dayjs(selectedEvent.start).minute() });
    setEditEndTime({ hours: dayjs(selectedEvent.end).hour(), minutes: dayjs(selectedEvent.end).minute() });
    setEditSelectedNave(selectedEvent.description.nave);
    setEditSelectedPonton(selectedEvent.description.ponton);
    setEditSelectedEspecie(selectedEvent.description.especie);
    setEditTonAnunciado(selectedEvent.description.tonAnunciado);
    setEditEventType(selectedEvent.title === "Pontón Fuera de Servicio" ? 'Fuera de servicio' : 'Normal');
    setEditEventModalVisible(true);
    setModalVisible(false); // Cierra el modal de detalles
  };
  

  
  const eventCellStyle = (event) => {
    const marginTop = events.some(e => dayjs(e.end).isSame(event.start)) ? 2 : 0;
    
    return {
      width: '43%', // Todos los eventos ocupan la mitad del espacio
      marginTop,
      marginHorizontal: 2,
      padding: 5,
      borderWidth: 1,
      borderColor: 'lightgray',
      borderRadius: 4,
      display: 'inline-block',
    };
  };
  
 
  // const renderEvent = (event, touchableOpacityProps) => {
  //   // Determinar el color del evento basado en el pontón
  //   const eventColor = event.description.ponton === "Cavancha" ? "#fc641a" : "#039be5"; // Naranjo para pontón 2, azul para los demás
  
  //   return (
  //     <TouchableOpacity
  //       {...touchableOpacityProps}
  //       style={[
  //         styles.eventContainer,
  //         touchableOpacityProps.style,
  //         {backgroundColor: eventColor}, // Aplica el color basado en el pontón
  //       ]}
  //     >
  //       <Text style={styles.eventTitle}>
  //     {`${event.title}`}
  //     {"\n"}{/* Salto de línea para el ponton */}
  //     {`Pontón: ${event.description.ponton}`}
  //     {"\n"}{/* Salto de línea para el intervalo horario */}
  //     {dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}
  //   </Text>
  //     </TouchableOpacity>
  //   );
  // };



//DESDE AQUIIIIII RENDER EVENT ANTIGUO
  // const renderEvent = (event, touchableOpacityProps) => {
  //   // Determinar el color del evento basado en el pontón
  //   const eventColor = event.description.ponton === "Cavancha" ? "rgba(252, 100, 26, 0.5)" : "rgba(3, 155, 229, 0.5)"; // Naranjo con opacidad para pontón 2, azul con opacidad para los demás
  //   const patternColor = event.description.ponton === "Cavancha" ? "#fc641a" : "#039be5"; // Color sólido para el patrón
  
  //   return (
  //     <TouchableOpacity
  //       {...touchableOpacityProps}
  //       style={[
  //         styles.eventContainer,
  //         touchableOpacityProps.style,
  //         { backgroundColor: eventColor }, // Aplica el color con opacidad
  //       ]}
  //     >
  //       <View style={styles.eventBackground}>
  //         <HatchPattern color={patternColor} />
  //       </View>
  //       <Text style={styles.eventTitle}>
  //         {`${event.title}`}
  //         {"\n"}{/* Salto de línea para el ponton */}
  //         {`Pontón: ${event.description.ponton}`}
  //         {"\n"}{/* Salto de línea para el intervalo horario */}
  //         {dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}
  //       </Text>
  //     </TouchableOpacity>
  //   );
  // };
  const renderEvent = (event, touchableOpacityProps) => {
    const eventColor = event.color === 'red'
      ? (event.description.ponton === "Cavancha" ? "rgba(252, 100, 26, 0.5)" : "rgba(3, 155, 229, 0.5)")
      : event.color;
    const patternColor = event.color === 'red' ? "#ffffff" : null;
    
    const fontSize = getDynamicFontSize();
    const isSmallScreen = screenWidth <= 768; // Define qué es una pantalla pequeña
  
    return (
      <TouchableOpacity
        {...touchableOpacityProps}
        style={[
          styles.eventContainer,
          touchableOpacityProps.style,
          { backgroundColor: eventColor },
        ]}
      >
        {event.color === 'red' && (
          <View style={styles.eventBackground}>
            <HatchPattern color={patternColor} />
          </View>
        )}
        <Text style={[styles.eventTitle, { fontWeight: 'bold', color: event.color === 'red' ? 'black' : 'white', fontSize }]}>
          {`${event.title}`}
          {"\n"}
          {`Pontón: ${event.description.ponton}`}
          {!isSmallScreen && (
            <>
              {"\n"}
              {dayjs(event.start).format('HH:mm')} - {dayjs(event.end).format('HH:mm')}
            </>
          )}
        </Text>
      </TouchableOpacity>
    );
  };
  
  return (
    <View style={styles.container}>
    <View style={styles.headerButtons}>
        <TouchableOpacity style={styles.buttonHeader} onPress={goToPreviousWeek}>
          <Text style={styles.buttonText}>SEMANA ANTERIOR</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttonHeader} onPress={goToCurrentWeek}>
          <Text style={styles.buttonText}>ESTA SEMANA</Text>
        </TouchableOpacity>
        <TouchableOpacity style={styles.buttonHeader} onPress={goToNextWeek}>
          <Text style={styles.buttonText}>SEMANA SIGUIENTE</Text>
        </TouchableOpacity>
      </View>
      <Calendar
        events={events.map(event => ({
          ...event,
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
        }))}
        height={screenHeight - 50}
        // date={new Date()}
        date={currentDate}
        style={styles.calendar}
        locale="es"
        onPressEvent={(event) => handleEventPress(event)}
        eventCellStyle={eventCellStyle}
        overlapOffset={customOverlapOffset}
        renderEvent={renderEvent}
        hourRowHeight={100}
        cellHeight={20}
        headerContainerStyle={styles.headerContainer}
        headerContentStyle={styles.headerContent}
      />





      {/* modal editar */}
      <Modal
  animationType="slide"
  transparent={true}
  visible={editEventModalVisible}
  onRequestClose={() => setEditEventModalVisible(false)}
>
  <View style={styles.centeredView}>
    <View style={styles.addEventModalView}>
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <View style={styles.inputContainer}>
          <Text style={styles.inputLabel}>Tipo de Evento</Text>
          <Picker
            style={styles.input}
            selectedValue={editEventType}
            onValueChange={(itemValue) => setEditEventType(itemValue)}
          >
            <Picker.Item label="Normal" value="Normal" />
            <Picker.Item label="Fuera de servicio" value="Fuera de servicio" />
          </Picker>
        </View>

        {editEventType === 'Normal' && (
          <>
            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>Toneladas anunciadas</Text>
              <TextInput
                style={styles.input}
                placeholder="Toneladas anunciadas"
                keyboardType="numeric"
                onChangeText={(text) => setEditTonAnunciado(text)}
                value={editTonAnunciado}
              />
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>Nave</Text>
              <Picker
                style={styles.input}
                selectedValue={editSelectedNave}
                onValueChange={(itemValue) => setEditSelectedNave(itemValue)}
              >
                <Picker.Item label="Seleccionar Nave" value="" />
                {naves.map((nave) => (
                  <Picker.Item key={nave} label={nave} value={nave} />
                ))}
              </Picker>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>Pontón</Text>
              <Picker
                style={styles.input}
                selectedValue={editSelectedPonton}
                onValueChange={(itemValue) => setEditSelectedPonton(itemValue)}
              >
                <Picker.Item label="Seleccionar Pontón" value="" />
                {pontones.map((ponton) => (
                  <Picker.Item key={ponton} label={ponton} value={ponton} />
                ))}
              </Picker>
            </View>

            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>Especie</Text>
              <Picker
                style={styles.input}
                selectedValue={editSelectedEspecie}
                onValueChange={(itemValue) => setEditSelectedEspecie(itemValue)}
              >
                <Picker.Item label="Seleccionar Especie" value="" />
                {especies.map((especie) => (
                  <Picker.Item key={especie} label={especie} value={especie} />
                ))}
              </Picker>
            </View>
          </>
        )}

        {editEventType === 'Fuera de servicio' && (
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Pontón</Text>
            <Picker
              style={styles.input}
              selectedValue={editSelectedPonton}
              onValueChange={(itemValue) => setEditSelectedPonton(itemValue)}
            >
              <Picker.Item label="Seleccionar Pontón" value="" />
              {pontones.map((ponton) => (
                <Picker.Item key={ponton} label={ponton} value={ponton} />
              ))}
            </Picker>
          </View>
        )}

        <TouchableOpacity onPress={() => setStartDateVisible(!isStartDateVisible)} style={styles.toggleButton}>
          <Text style={styles.buttonText}>
            {isStartDateVisible ? "Ocultar Fecha de Inicio" : "Agregar Fecha de Inicio"}
          </Text>
        </TouchableOpacity>

        {isStartDateVisible && (
          <View style={styles.inputContainer}>
            <Text style={styles.inputLabel}>Fecha de Inicio</Text>
            <CalendarPicker
              width={'100%'}
              height={300}
              weekdays={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
              months={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
              current={editEventDate.format('YYYY-MM-DD')}
              markedDates={{
                [editEventDate.format('YYYY-MM-DD')]: { selected: true, selectedColor: '#4484f4' }
              }}
              onDayPress={(day) => {
                setEditEventDate(dayjs(day.dateString));
              }}
              theme={{
                arrowColor: '#4484f4', // Color de las flechas
                monthTextColor: '#4484f4', // Color del texto del mes
                textMonthFontWeight: 'bold',
                'stylesheet.calendar.header': {
                  header: {
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  },
                },
              }}
              renderArrow={(direction) => <Arrow direction={direction} />}
            />
          </View>
        )}


            <TouchableOpacity onPress={() => setEditEndDateVisible(!isEditEndDateVisible)} style={styles.toggleButton}>
              <Text style={styles.buttonText}>
                {isEditEndDateVisible ? "Ocultar Fecha de Fin" : "Agregar Fecha de Fin"}
              </Text>
            </TouchableOpacity>

          {isEditEndDateVisible && (
            <View style={styles.inputContainer}>
              <Text style={styles.inputLabel}>Fecha de Fin</Text>
              <CalendarPicker
                width={'100%'}
                height={300}
                weekdays={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
                months={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                current={editEventEndDate.format('YYYY-MM-DD')}
                markedDates={{
                  [editEventEndDate.format('YYYY-MM-DD')]: { selected: true, selectedColor: '#4484f4' }
                }}
                onDayPress={(day) => {
                  setEditEventEndDate(dayjs(day.dateString));
                }}
                theme={{
                  arrowColor: '#4484f4', // Color de las flechas
                  monthTextColor: '#4484f4', // Color del texto del mes
                  textMonthFontWeight: 'bold',
                  'stylesheet.calendar.header': {
                    header: {
                      flexDirection: 'row',
                      justifyContent: 'center',
                      alignItems: 'center',
                    },
                  },
                }}
                renderArrow={(direction) => <Arrow direction={direction} />}
              />
            </View>
          )}

        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Button onPress={() => setStartPickerVisible(true)} title="Seleccionar Hora de Inicio" />
          <Text>Hora de inicio: {`${editStartTime.hours}:${editStartTime.minutes.toString().padStart(2, '0')}`}</Text>

          <Button onPress={() => setEndPickerVisible(true)} title="Seleccionar Hora de Fin" />
          <Text>Hora de fin: {`${editEndTime.hours}:${editEndTime.minutes.toString().padStart(2, '0')}`}</Text>

          <TimePickerModal
            visible={isStartPickerVisible}
            onDismiss={onDismissStartPicker}
            onConfirm={onConfirmStartPicker}
            hours={editStartTime.hours}
            minutes={editStartTime.minutes}
            label="Hora de inicio"
          />

          <TimePickerModal
            visible={isEndPickerVisible}
            onDismiss={onDismissEndPicker}
            onConfirm={onConfirmEndPicker}
            hours={editEndTime.hours}
            minutes={editEndTime.minutes}
            label="Hora de fin"
          />
        </View>

        <TouchableOpacity
          style={styles.saveButton}
          onPress={updateEvent}
        >
          <Text style={styles.saveButtonText}>Guardar Cambios</Text>
        </TouchableOpacity>
      </ScrollView>
    </View>
  </View>
</Modal>

<Modal
    animationType="slide"
    transparent={true}
    visible={modalVisible}
    onRequestClose={() => {
      setModalVisible(!modalVisible);
    }}
  >
    <View style={styles.centeredView}>
      <View style={styles.modalView}>
        <View style={styles.modalHeader}>
          <TouchableOpacity style={styles.button} onPress={() => editEvent()}>
            <FiEdit2 size={20} color="#555" />
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={() => deleteEvent()}>
            <MdDelete size={20} color="#555" />
          </TouchableOpacity>
          <TouchableOpacity style={styles.button} onPress={() => setModalVisible(!modalVisible)}>
            <MdClose size={24} color="#555" />
          </TouchableOpacity>
        </View>
        <Text style={styles.modalText}>{selectedEvent?.title}</Text>
        <Text style={styles.modalDate}>
          {`${dayjs(selectedEvent?.start).format('HH:mm')} - ${dayjs(selectedEvent?.end).format('HH:mm')}`}
        </Text>
        
        {selectedEvent?.title !== "Pontón Fuera de Servicio" && (
          <Text style={styles.modalDescription}>
            {`Nave: ${selectedEvent?.description.nave}, Ton Anunciado: ${selectedEvent?.description.tonAnunciado}, Especie: ${selectedEvent?.description.especie}`}
          </Text>
        )}
        <Text style={styles.modalDescription}>
          {`Pontón: ${selectedEvent?.description.ponton}`}
        </Text>
      </View>
    </View>
  </Modal>

      <TouchableOpacity
        style={styles.addButton}
        onPress={() => setAddEventModalVisible(true)}
      >
        <MdAddCircle size={65} color="#4484f4" />
      </TouchableOpacity>

      <Modal
        animationType="slide"
        transparent={true}
        visible={addEventModalVisible}
        onRequestClose={() => setAddEventModalVisible(false)}
      >
        <View style={styles.centeredView}>
          <View style={styles.addEventModalView}>
            <ScrollView contentContainerStyle={styles.scrollContainer}>
              <View style={styles.inputContainer}>
                <Text style={styles.inputLabel}>Tipo de Evento</Text>
                <Picker
                  style={styles.input}
                  selectedValue={eventType}
                  onValueChange={(itemValue, itemIndex) => setEventType(itemValue)}
                >
                  <Picker.Item label="Normal" value="Normal" />
                  <Picker.Item label="Fuera de servicio" value="Fuera de servicio" />
                </Picker>
              </View>

              {eventType === 'Normal' && (
                <>
                  <View style={styles.inputContainer}>
                    <Text style={styles.inputLabel}>Toneladas anunciadas</Text>
                    <TextInput
                      style={styles.input}
                      placeholder="Toneladas anunciadas"
                      keyboardType="numeric"
                      onChangeText={(text) => setTonAnunciado(text)}
                      value={tonAnunciado}
                    />
                  </View>

                  <View style={styles.inputContainer}>
                    <Text style={styles.inputLabel}>Nave</Text>
                    <Picker
                      style={styles.input}
                      selectedValue={selectedNave}
                      onValueChange={(itemValue, itemIndex) => setSelectedNave(itemValue)}
                    >
                      <Picker.Item label="Seleccionar Nave" value="" />
                      {naves.map((nave) => (
                        <Picker.Item key={nave} label={nave} value={nave} />
                      ))}
                    </Picker>
                  </View>

                  <View style={styles.inputContainer}>
                    <Text style={styles.inputLabel}>Pontón</Text>
                    <Picker
                      style={styles.input}
                      selectedValue={selectedPonton}
                      onValueChange={(itemValue, itemIndex) => setSelectedPonton(itemValue)}
                    >
                      <Picker.Item label="Seleccionar Pontón" value="" />
                      {pontones.map((ponton) => (
                        <Picker.Item key={ponton} label={ponton} value={ponton} />
                      ))}
                    </Picker>
                  </View>

                  <View style={styles.inputContainer}>
                    <Text style={styles.inputLabel}>Especie</Text>
                    <Picker
                      style={styles.input}
                      selectedValue={selectedEspecie}
                      onValueChange={(itemValue, itemIndex) => setSelectedEspecie(itemValue)}
                    >
                      <Picker.Item label="Seleccionar Especie" value="" />
                      {especies.map((especie) => (
                        <Picker.Item key={especie} label={especie} value={especie} />
                      ))}
                    </Picker>
                  </View>
                </>
              )}

              {eventType === 'Fuera de servicio' && (
                <View style={styles.inputContainer}>
                  <Text style={styles.inputLabel}>Pontón</Text>
                  <Picker
                    style={styles.input}
                    selectedValue={selectedPonton}
                    onValueChange={(itemValue, itemIndex) => setSelectedPonton(itemValue)}
                  >
                    <Picker.Item label="Seleccionar Pontón" value="" />
                    {pontones.map((ponton) => (
                      <Picker.Item key={ponton} label={ponton} value={ponton} />
                    ))}
                  </Picker>
                </View>
              )}

              <TouchableOpacity onPress={() => setStartDateVisible(!isStartDateVisible)} style={styles.toggleButton}>
                <Text style={styles.buttonText}>
                  {isStartDateVisible ? "Ocultar Fecha de Inicio" : "Agregar Fecha de Inicio"}
                </Text>
              </TouchableOpacity>

              {isStartDateVisible && (
                <View style={styles.inputContainer}>
                  <Text style={styles.inputLabel}>Fecha de Inicio</Text>
                  <CalendarPicker
                    width={'100%'}
                    height={300}
                    weekdays={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
                    months={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                    current={date.format('YYYY-MM-DD')}
                    markedDates={{
                      [date.format('YYYY-MM-DD')]: { selected: true, selectedColor: '#4484f4' }
                    }}
                    theme={{
                      arrowColor: '#4484f4', // Color de las flechas
                      monthTextColor: '#4484f4', // Color del texto del mes
                      textMonthFontWeight: 'bold',
                      'stylesheet.calendar.header': {
                        header: {
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                      },
                    }}
                    renderArrow={(direction) => <Arrow direction={direction} />}
                  />
                </View>
              )}

              <TouchableOpacity onPress={() => setEndDateVisible(!isEndDateVisible)} style={styles.toggleButton}>
                <Text style={styles.buttonText}>
                  {isEndDateVisible ? "Ocultar Fecha de Fin" : "Agregar Fecha de Fin"}
                </Text>
              </TouchableOpacity>

              {isEndDateVisible && (
                <View style={styles.inputContainer}>
                  <Text style={styles.inputLabel}>Fecha de Fin</Text>
                  <CalendarPicker
                    width={'100%'}
                    height={300}
                    weekdays={['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sáb']}
                    months={['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre']}
                    current={newEventEndDate.format('YYYY-MM-DD')}
                    markedDates={{
                      [newEventEndDate.format('YYYY-MM-DD')]: { selected: true, selectedColor: '#4484f4' }
                    }}
                    theme={{
                      arrowColor: '#4484f4', // Color de las flechas
                      monthTextColor: '#4484f4', // Color del texto del mes
                      textMonthFontWeight: 'bold',
                      'stylesheet.calendar.header': {
                        header: {
                          flexDirection: 'row',
                          justifyContent: 'center',
                          alignItems: 'center',
                        },
                      },
                    }}
                    renderArrow={(direction) => <Arrow direction={direction} />}
                  />
                </View>
              )}
              <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                <Button onPress={() => setStartPickerVisible(true)} title="Seleccionar Hora de Inicio" />
                <Text>Hora de inicio: {`${startTime.hours}:${startTime.minutes.toString().padStart(2, '0')}`}</Text>

                <Button onPress={() => setEndPickerVisible(true)} title="Seleccionar Hora de Fin" />
                <Text>Hora de fin: {`${endTime.hours}:${endTime.minutes.toString().padStart(2, '0')}`}</Text>

                <TimePickerModal
                  visible={isStartPickerVisible}
                  onDismiss={onDismissStartPicker}
                  onConfirm={onConfirmStartPicker}
                  hours={startTime.hours}
                  minutes={startTime.minutes}
                  label="Hora de inicio"
                />

                <TimePickerModal
                  visible={isEndPickerVisible}
                  onDismiss={onDismissEndPicker}
                  onConfirm={onConfirmEndPicker}
                  hours={endTime.hours}
                  minutes={endTime.minutes}
                  label="Hora de fin"
                />
              </View>

              <TouchableOpacity
                style={styles.saveButton}
                onPress={addNewEvent}
              >
                <Text style={styles.saveButtonText}>Guardar Evento</Text>
              </TouchableOpacity>
            </ScrollView>
          </View>
        </View>
      </Modal>
    </View>
  );
};
const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  calendar: {
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 15,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  addButton: {
    padding: 10,
    position: 'absolute',
    bottom: 20,
    right: 20,
    borderRadius: 20,
    // Estilo de sombra para el efecto de relieve
    // shadowOffset: { width: 2, height: 2 }, // Tamaño de la sombra
    // shadowColor: '#000000', // Color de la sombra
    // shadowOpacity: 0.3, // Opacidad de la sombra
    // elevation: 3, // Elevación para dispositivos Android
  },
  addButtonText: {
    color: 'white',
    fontSize: 16,
    textAlign: 'center',
  },
  addEventModalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: '100%',
  },
  input: {
    width: '100%',
    padding: 10,
    marginBottom: 10,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
  },
  inputDescription: {
    height: 100,
  },
  saveButton: {
    marginTop: 20,
    backgroundColor: 'green',
    padding: 10,
    width: '100%',
    alignItems: 'center',
    borderRadius: 5,
  },
  saveButtonText: {
    color: 'white',
    fontSize: 16,
  },
  dateTimePicker: {
    width: '100%',
    marginVertical: 20,
  },
  modalHeader: {
    width: '100%',
    flexDirection: 'row',
    marginBottom: 15,
    alignItems: 'right',
  },
  pickerStyle: {
    height: 50,
    width: 150,
    alignSelf: 'center',
  },
  eventTitle: {
    fontSize: getDynamicFontSize(),
    color: '#fff', // Cambia el color según tu diseño
    textAlign: 'left', // Alineación a la izquierda
  },
  eventTime: {
    fontSize: 10, // Más pequeño que el título
    color: '#fff', // Igual que el título para consistencia
  },
  // Puedes ajustar los estilos del TouchableOpacity para que se parezca a tus imágenes
  eventContainer: {
    margin: 1,
    padding: 5,
    borderRadius: 4,
    backgroundColor: 'blue', // O el color que prefieras
    overflow: 'hidden', // Asegura que el contenido no sobresalga
  },





  eventContainer: {
    margin: 1,
    padding: 5,
    borderRadius: 4,
    backgroundColor: 'blue', // Puedes ajustar este color según tus necesidades
    overflow: 'hidden',
  },
  eventTitle: {
    fontSize: 10, // Reduce el tamaño del texto del título
    color: '#fff',
    
  },
  eventTime: {
    fontSize: 8, // Reduce el tamaño del texto de las horas
    color: '#fff',
  },


  toggleStartDateText: {
    color: '#4484f4',
    fontSize: 16,
    marginVertical: 10,
    textDecorationLine: 'underline',
  },
  toggleEndDateText: {
    color: '#4484f4',
    fontSize: 16,
    marginVertical: 10,
    textDecorationLine: 'underline',
   
  },
  toggleButton: {
    backgroundColor: '#4484f4',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignItems: 'center',
    padding:2,
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },





  scrollContainer: {
    flexGrow: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  addEventModalView: {
    margin: 20,
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 20,
    alignItems: 'center',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
    width: '90%', // Ajusta el ancho del modal
    maxWidth: 300, // Establece un ancho máximo para el modal
    maxHeight: '90%', // Limitar la altura del modal para evitar que se salga de la pantalla
  },
  inputContainer: {
    marginBottom: 20,
    width: '90%',
  },
  inputLabel: {
    marginBottom: 5,
    fontSize: 16,
    fontWeight: 'bold',
    textAlign: 'left', // Alinea el texto a la izquierda
  },
  input: {
    borderWidth: 1,
    borderColor: '#ccc',
    borderRadius: 5,
    padding: 10,
    fontSize: 16,
  },
  toggleButton: {
    backgroundColor: '#2196F3',
    padding: 10,
    borderRadius: 5,
    marginVertical: 10,
    alignItems: 'center',
    width: '90%',
   
  },
  buttonText: {
    color: '#fff',
    fontSize: 16,
  },
  saveButton: {
    backgroundColor: '#2196F3',
    borderRadius: 5,
    padding: 10,
    elevation: 2,
    alignItems: 'center',
    width: '90%',
  },
  saveButtonText: {
    color: 'white',
    fontWeight: 'bold',
    fontSize: 16,
  },


  dayHeader: {
    fontSize: 34, // Aquí defines el tamaño de la fuente que deseas
    // Puedes agregar otras propiedades de estilo como color, fontWeight, etc.
  },





  headerContainer: {
    height: 58, // Ajusta la altura del contenedor del header
  },
  headerContent: {
    fontSize: 18, // Tamaño de fuente del contenido del header
    color: '#333', // Color de texto del contenido del header
  },


  eventBackground: {
    ...StyleSheet.absoluteFillObject,
    opacity: 0.3, // Ajusta la opacidad del patrón si es necesario
  },


  headerButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 10,
    alignItems: 'center',
  },
  buttonHeader: {
    backgroundColor: '#4484f4',
    paddingVertical: 10,
    paddingHorizontal: 20,
    borderRadius: 20,
  },
});

export default AdminCalendarScreen;
