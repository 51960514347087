// EventApi.js
import dayjs from 'dayjs';
const baseUrl = 'https://calendariopesca-back-qa.camanchaca.cl'; // Reemplaza con la URL base de tu API

// export const getEvents = async () => {
//   try {
//     const response = await fetch(`${baseUrl}/api/events/`);
//     if (!response.ok) {
//       throw new Error('Network response was not ok');
//     }
//     return await response.json();
//   } catch (error) {
//     console.error("Could not get events:", error);
//   }
// };

export const getEvents = async () => {
  try {
    const response = await fetch(`${baseUrl}/api/events/`);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const events = await response.json();

    // Modificar las horas de los eventos
    const updatedEvents = events.map(event => {
      const adjustedStart = dayjs(event.start).add(4, 'hour').toISOString();
      const adjustedEnd = dayjs(event.end).add(4, 'hour').toISOString();
      console.log('Original start:', event.start, 'Adjusted start:', adjustedStart);
      console.log('Original end:', event.end, 'Adjusted end:', adjustedEnd);

      return {
        ...event,
        start: adjustedStart,
        end: adjustedEnd,
      };
    });

    return updatedEvents;
  } catch (error) {
    console.error("Could not get events:", error);
  }
};

export const createEvent = async (eventData) => {
    try {
      const response = await fetch(`${baseUrl}/api/events/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(eventData),
      });
  
      console.log("Response", response);
      const textResponse = await response.text(); // Obtiene la respuesta como texto
      console.log("Text response", textResponse);
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      try {
        // Intenta convertir la respuesta de texto a JSON
        const jsonResponse = JSON.parse(textResponse);
        console.log("JSON response", jsonResponse);
        return jsonResponse;
      } catch (e) {
        // Si JSON.parse falla, significa que la respuesta no era JSON
        console.error("Error parsing JSON:", e);
        // Puedes decidir qué hacer en este caso. Tal vez solo retornar el texto?
        return textResponse;
      }
    } catch (error) {
      console.error("Could not create event:", error);
    }
  };
  


// Función para actualizar eventos
// Función para actualizar un evento
export const updateEventApi = async (eventData) => {
  try {
    // Convertir las fechas al formato 'YYYY-MM-DD HH:mm:ss'
    const start = dayjs(eventData.start).format('YYYY-MM-DD HH:mm:ss');
    const end = dayjs(eventData.end).format('YYYY-MM-DD HH:mm:ss');

    const response = await fetch(`${baseUrl}/api/events/${eventData.id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        ...eventData,
        start,
        end,
      }),
    });
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    return await response.json();
  } catch (error) {
    console.error("Could not update event:", error);
  }
};


  // Definir la función para eliminar eventos
export const deleteEvent = async (eventId) => {
  try {
    const response = await fetch(`${baseUrl}/api/events/${eventId}`, {
      method: 'DELETE',
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    
    return await response.text();
  } catch (error) {
    console.error("Could not delete event:", error);
  }
};
// Agrega funciones adicionales para actualizar y eliminar eventos
export const login = async (username, password) => {
  try {
    const response = await fetch(`${baseUrl}/api/users/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ username, password })
    });

    if (!response.ok) {
      throw new Error('Login failed');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Login error:", error);
    throw error;
  }
};