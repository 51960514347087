import React from 'react';
import Svg, { Defs, Pattern, Rect, Line } from 'react-native-svg';

const HatchPattern = ({ color }) => (
  <Svg height="100%" width="100%">
    <Defs>
      <Pattern id="hatch" patternUnits="userSpaceOnUse" width="10" height="10">
        <Line x1="0" y1="0" x2="10" y2="10" stroke={`${color}40`} strokeWidth="2" /> {/* Opacidad al 50% */}
        <Line x1="10" y1="0" x2="0" y2="10" stroke={`${color}40`} strokeWidth="2" /> {/* Opacidad al 50% */}
      </Pattern>
    </Defs>
    <Rect width="100%" height="100%" fill="url(#hatch)" />
  </Svg>
);

export default HatchPattern;
