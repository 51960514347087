import React, { createContext, useContext, useState } from 'react';

export const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  const login = (userData) => {
    setUser(userData);  // Función para establecer el usuario como autenticado
  };

  const logout = () => {
    setUser(null);  // Función para limpiar el estado del usuario (cerrar sesión)
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
