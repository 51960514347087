import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Text, TouchableOpacity, Dimensions, SafeAreaView, Modal, Image, ActivityIndicator } from 'react-native';
import { Calendar } from 'react-native-big-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import { getEvents } from '../api/EventApi';
import { MdClose } from "react-icons/md";
import HatchPattern from './HatchPattern';
import WebFont from 'webfontloader'; // Importa webfontloader

const getDynamicOverlapOffset = (windowWidth) => {
  const baseWidth = 1024;
  const baseOffset = 150;
  const minimumOffset = 50;
  const calculatedOffset = (windowWidth / baseWidth) * baseOffset;
  return Math.max(calculatedOffset, minimumOffset);
};

const getDynamicFontSize = (width) => {
  const baseWidth = 768;
  const scaleRatio = 0.35;
  const minFontSize = 10;
  const baseFontSize = 14;
  return Math.max(minFontSize, (width / baseWidth) * scaleRatio * baseFontSize);
};

const CalendarComponent = ({ navigation }) => {
  const [events, setEvents] = useState([]);
  const [dimensions, setDimensions] = useState(Dimensions.get('window'));
  const [fontSize, setFontSize] = useState(getDynamicFontSize(dimensions.width));
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [fontsLoaded, setFontsLoaded] = useState(false);

  useEffect(() => {
    // Cargar Google Fonts
    WebFont.load({
      google: {
        families: ['Montserrat:300,400,500,700', 'sans-serif']
      },
      active: () => setFontsLoaded(true),
    });
  }, []);

  useEffect(() => {
    const subscription = Dimensions.addEventListener('change', ({ window }) => {
      setDimensions(window);
    });
    return () => subscription?.remove();
  }, []);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const fetchedEvents = await getEvents();
        setEvents(fetchedEvents.map(event => ({
          title: event.title,
          start: dayjs(event.start).toDate(),
          end: dayjs(event.end).toDate(),
          description: event.description,
          color: event.color,
        })));
      } catch (error) {
        console.error("Error al obtener eventos:", error);
      }
    };

    fetchEvents();
  }, []);

  const [customOverlapOffset, setCustomOverlapOffset] = useState(() => {
    const initialWidth = Dimensions.get('window').width;
    return getDynamicOverlapOffset(initialWidth);
  });

  const handleEventPress = (event) => {
    setSelectedEvent(event);
    setModalVisible(true);
  };

  useEffect(() => {
    const updateOffset = () => {
      const newWidth = Dimensions.get('window').width;
      setCustomOverlapOffset(getDynamicOverlapOffset(newWidth));
    };

    Dimensions.addEventListener('change', updateOffset);

    return () => Dimensions.removeEventListener('change', updateOffset);
  }, []);

  const renderEvent = (event, touchableOpacityProps) => {
    const isDisabledEvent = event.title === "Pontón Fuera de Servicio";
    const eventColor = isDisabledEvent
      ? (event.description.ponton === "Cavancha" ? "rgba(252, 100, 26, 0.2)" : "rgba(3, 155, 229, 0.2)")
      : (event.description.ponton === "Cavancha" ? "#fc641a" : "#039be5");
    const textColor = isDisabledEvent ? '#000' : '#fff';

    return (
      <TouchableOpacity
        {...touchableOpacityProps}
        style={[
          styles.eventCell,
          touchableOpacityProps.style,
          { backgroundColor: eventColor },
        ]}
      >
        {isDisabledEvent && (
          <View style={styles.eventBackground}>
            <HatchPattern color="#ffffff" />
          </View>
        )}
        <Text style={[styles.eventText, { fontSize, color: textColor }]}>{event.title}</Text>
        <Text style={[styles.eventText, { fontSize, color: textColor }]}>{`Pontón: ${event.description.ponton}`}</Text>
        {event.description.especie && (
          <Text style={[styles.eventText, { fontSize, color: textColor }]}>{`Especie: ${event.description.especie} - Ton: ${event.description.tonAnunciado}`}</Text>
        )}
        <Text style={[styles.eventText, { fontSize, color: textColor }]}>{`${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}`}</Text>
      </TouchableOpacity>
    );
  };

  if (!fontsLoaded) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <SafeAreaView style={styles.container}>
      <View style={styles.navbar}>
        <Image source={require('../images/Camanchaca Horizontal Azul.png')} style={styles.logo} />
        <Text style={styles.title}>Calendario Descargas Pesca</Text>
      </View>
      <Calendar
        events={events}
        height={dimensions.height - 150}
        style={styles.calendar}
        date={new Date()}
        onPressEvent={(event) => handleEventPress(event)}
        locale="es"
        mode='3days'
        eventCellStyle={styles.eventCell}
        hourRowHeight={70}
        showSixWeeks={false}
        renderEvent={renderEvent}
        overlapOffset={customOverlapOffset}
        headerContainerStyle={styles.headerContainer}
        headerContentStyle={styles.headerContent}
      />
      <Modal
        animationType="slide"
        transparent={true}
        visible={modalVisible}
        onRequestClose={() => {
          setModalVisible(!modalVisible);
        }}
      >
        <View style={styles.centeredView}>
          <View style={styles.modalView}>
            <Text style={styles.modalText}>{selectedEvent?.title}</Text>
            <Text style={styles.modalDate}>
              {dayjs(selectedEvent?.start).format('HH:mm')} - {dayjs(selectedEvent?.end).format('HH:mm')}
            </Text>
            {selectedEvent?.title !== "Pontón Fuera de Servicio" && (
              <Text style={styles.modalDescription}>
                {`Nave: ${selectedEvent?.description.nave}, Ton Anunciado: ${selectedEvent?.description.tonAnunciado}, Especie: ${selectedEvent?.description.especie}`}
              </Text>
            )}
            <Text style={styles.modalDescription}>
              {`Pontón: ${selectedEvent?.description.ponton}`}
            </Text>
            <TouchableOpacity
              style={styles.closeButton}
              onPress={() => setModalVisible(false)}
            >
              <MdClose size={30} color="#000" />
            </TouchableOpacity>
          </View>
        </View>
      </Modal>
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
  navbar: {
    height: 60,
    backgroundColor: '#f0f0f0', // Ajusta este color al del calendario si es necesario
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  logo: {
    width: 100, // Puedes ajustar el tamaño aquí
    height: 40,
    resizeMode: 'contain',
  },
  title: {
    fontSize: 20, // Ajusta el tamaño de la fuente aquí
    fontFamily: 'Montserrat', // Usa la fuente cargada desde Google Fonts
    color: '#333', // Cambia el color del texto si es necesario
  },
  calendar: {
    borderWidth: 1,
    borderColor: '#000',
    borderRadius: 5,
    flex: 1,
  },
  eventCell: {
    padding: 5,
    borderWidth: 1,
    borderColor: 'lightgray',
    borderRadius: 4,
    width: '45%',
    marginRight: '4%',
  },
  eventText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  centeredView: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalText: {
    marginBottom: 15,
    textAlign: "center",
  },
  modalDate: {
    fontSize: 18,
    marginBottom: 20,
  },
  modalDescription: {
    fontSize: 16,
    color: '#333',
  },
  closeButton: {
    position: 'absolute',
    top: 10,
    right: 10,
  },
  headerContainer: {
    height: 58,
  },
  headerContent: {
    fontSize: 18,
  },
  eventBackground: {
    ...StyleSheet.absoluteFillObject,
    zIndex: -1,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },

  navbar: {
    height: 60,
    backgroundColor: '#fff', // Cambia el fondo a blanco
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  logo: {
    width: 100,
    height: 40,
    resizeMode: 'contain',
  },
  title: {
    fontSize: 15, 
    color: '#039be5', // Cambia el color del texto si es necesario
  },
});

export default CalendarComponent;
