import React, { createContext, useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { getEvents } from '../api/EventApi'; // Asegúrate de que la ruta es correcta

export const CalendarContext = createContext();
const checkDate = (dateString, offset) => {
  // Ajusta la fecha por un desfase horario conocido, donde offset está en horas
  return dayjs(dateString).add(offset, 'hour').format('YYYY-MM-DD HH:mm:ss');
};
export const CalendarProvider = ({ children }) => {
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const loadEvents = async () => {
      try {
        const fetchedEvents = await getEvents();
        if (fetchedEvents) {
          const processedEvents = fetchedEvents.map(event => ({
            ...event,
            start: event.start,
            end: event.end,
            // Asegúrate de que la descripción es un objeto, si es necesario
            description: typeof event.description === 'string' ? JSON.parse(event.description) : event.description,
          }));
          setEvents(processedEvents);
        }
      } catch (error) {
        console.error("Error loading events:", error);
      }
    };

    loadEvents();
  }, []);

  const addEvent = (newEvent) => {
    setEvents(currentEvents => [...currentEvents, {
      ...newEvent,
      start: checkDate(newEvent.start),
      end: checkDate(newEvent.end),
    }]);
  };

  return (
    <CalendarContext.Provider value={{ events, addEvent }}>
      {children}
    </CalendarContext.Provider>
  );
};
