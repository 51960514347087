
// import React from 'react';
// import { NavigationContainer } from '@react-navigation/native';
// import { createStackNavigator } from '@react-navigation/stack';
// import { CalendarProvider } from './src/context/CalendarContext';
// import HomeScreen from './src/screens/HomeScreen';
// import AdminCalendarScreen from './src/screens/AdminCalendarScreen';
// import {  View } from 'react-native';
// const Stack = createStackNavigator();

// const App = () => {
//   return (
//     <View style={{flex: 1}}> 
//     <CalendarProvider>
//       <NavigationContainer>
//         <Stack.Navigator initialRouteName="Home" screenOptions={{ headerShown: false,
//     cardStyle: { backgroundColor: 'transparent' } }}
//         >
//           <Stack.Screen name="Home" component={HomeScreen} />
//           <Stack.Screen name="Admin" component={AdminCalendarScreen} />
//         </Stack.Navigator>
//       </NavigationContainer>
//     </CalendarProvider>
//     </View>
//   );
// };

// export default App;
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import { CalendarProvider } from './src/context/CalendarContext';
import HomeScreen from './src/screens/HomeScreen';
import AdminCalendarScreen from './src/screens/AdminCalendarScreen';
import LoginScreen from './src/screens/LoginScreen';
import { View } from 'react-native';
import { AuthProvider } from './src/context/AuthContext'; // Importa AuthProvider
import AuthGuard from './src/auth/AuthGuard'; // Asegúrate de que la ruta es correcta

const Stack = createStackNavigator();

const linking = {
  prefixes: [], // Aquí puedes agregar el prefijo de tu dominio si es necesario, por ejemplo: ['https://myapp.com']
  config: {
    screens: {
      Home: '',      // Esto significa que 'Home' está asociado con la URL raíz '/'
      Admin: 'admin' // Esto asocia 'Admin' con la ruta '/admin'
      // Puedes definir rutas adicionales aquí según sea necesario
    },
  },
};

const App = () => {
  return (
    <AuthProvider> {/* Envuelve toda tu aplicación en AuthProvider */}
      <View style={{flex: 1}}> 
        <CalendarProvider>
          <NavigationContainer linking={linking}>
            <Stack.Navigator initialRouteName="Home" screenOptions={{ headerShown: false, cardStyle: { backgroundColor: 'transparent' } }}>
              <Stack.Screen name="Home" component={HomeScreen} />
              <Stack.Screen name="Admin">
                {() => (
                  <AuthGuard>
                    <AdminCalendarScreen />
                  </AuthGuard>
                )}
              </Stack.Screen>
              <Stack.Screen name="Login" component={LoginScreen} />
            </Stack.Navigator>
          </NavigationContainer>
        </CalendarProvider>
      </View>
    </AuthProvider>
  );
};

export default App;


